@media(max-width: 600px) {
    .user-card-body {
        flex-direction: column;
        flex-wrap: wrap;
    }

    .user-left,
    .user-right {
        width: 100% !important;
        height: auto;
        margin: 0 !important;
        border-left: none !important;
    }

    .user-right {
        padding-top: 5rem;
        border-top: 1px solid #ABB2B9;
    }
}