.tech-stack-component-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 2em;
}

.tech-stack-component-button {
    height: auto;
    padding: 1em 0.5em;
    color: #566573;
    display: flex;
    border:none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1em;
    font-size: 1.5em;
    font-weight: 500;
    text-align: start;
    background: #fefdfd;
}