* {
  margin: 0;
  padding: 0;
}

.r-container {
  width: 840px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 2rem;
  box-sizing: border-box;
  color: #273746;
  margin: 3.5rem auto;
  justify-content: center;
  position: relative;
  font-family: Arial, Helvetica, sans-serif;

  /* Set the background image using the ::before pseudo-element */
  position: relative;
  z-index: 1;
}

.resumeBuilder-watermark {
  position: absolute;
  right: 2px;
  top: 2px;
}

header {
  width: fit-content;
  margin: auto;
  margin-bottom: 1.6rem;
  font-family: Arial, Helvetica, sans-serif;
}

header > h1 {
  font-size: 3.2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
}

/* Education section */
.r-education {
  display: list-item;
}

/* Skills Education */
.r-skills {
  display: grid;
  grid-template-columns: 150px 50px auto;
  gap: 20px;
  margin-bottom: 20px;
  /* word-spacing: 4px; */
  font-size: 18px;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
}

.r-projects {
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
}

.r-projects-list {
  margin-bottom: 4rem;
  font-family: Arial, Helvetica, sans-serif;
}

.r-subheader {
  display: grid;
  grid-template-columns: 170px 40px auto;
}

.section {
  page-break-inside: avoid;
  margin-bottom: 3rem;
}

.r-exp-item {
  display: flex;
  justify-content: space-between;
}

.r-sub-headings {
  margin-bottom: 2.5rem;
  font-weight: bolder;
  font-size: 2.8rem;
}

.r-summary {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 3rem;
  /* display: list-item; */
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}

.secondary-heading {
  font-size: 1.6rem;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}

.project-headings {
  font-size: 1.6rem;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}

.r-project-subheading {
  font-weight: bold;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

@media print {
  .r-container::before {
    content: url("logo.png");
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.3;
    z-index: 9999;
  }
}

@page {
  size: A4 !important;
  margin: 3.5rem auto !important;
}

/* CSS */
.button-88 {
  display: flex;
  align-items: center;
  font-family: inherit;
  font-weight: 500;
  font-size: 16px;
  padding: 0.7em 1.4em 0.7em 1.1em;
  color: white;
  background: #ad5389;
  background: linear-gradient(
    0deg,
    rgba(20, 167, 62, 1) 0%,
    rgba(102, 247, 113, 1) 100%
  );
  border: none;
  box-shadow: 0 0.7em 1.5em -0.5em #14a73e98;
  letter-spacing: 0.05em;
  border-radius: 20em;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-88:hover {
  box-shadow: 0 0.5em 1.5em -0.5em #14a73e98;
}

.button-88:active {
  box-shadow: 0 0.3em 1em -0.5em #14a73e98;
}

/* CSS 2*/
.button-89 {
  display: flex;
  align-items: center;
  font-family: inherit;
  font-weight: 500;
  font-size: 16px;
  padding: 0 4rem;
  color: white;
  background: #ad5389;
  background: linear-gradient(
    0deg,
    rgb(55, 125, 211) 0%,
    rgb(11, 129, 203) 100%
  );
  border: none;
  box-shadow: 0 0.7em 1.5em -0.5em #0d70d498;
  letter-spacing: 0.05em;
  border-radius: 20em;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-89:hover {
  box-shadow: 0 0.5em 1.5em -0.5em #238bd198;
}

.button-89:active {
  box-shadow: 0 0.3em 1em -0.5em #1977e298;
}
