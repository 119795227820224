.uTSHome {
  &__boldPurpleText {
    font-weight: bold;
    color: #7500f3;
    margin-bottom: 2rem;
  }

  &__largeBlackText {
    font-size: 52px;
    text-align: center;
    font-weight: bold;
    color: black;
    margin-bottom: 2rem;
  }

  &__subHeading {
    text-align: center;
    margin-bottom: 2rem;
  }
}

.questionnaire {
  &__stepperAndButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      text-transform: none;
      font-size: 14px;
      font-weight: bold;
    }
  }

  // The styles applies to the stepper
  .MuiStepLabel-iconContainer {
    padding: 8px;
  }
  .MuiStepIcon-root.MuiStepIcon-active {
    color: #029863 !important;
    font-size: 2.2rem;
  }
  .MuiStepIcon-root.MuiStepIcon-active,
  .MuiStepIcon-completed {
    color: #029863 !important;
  }
  .MuiStepper-root {
    width : 50%;
    justify-content: center;
  }

  &__questionTypography {
    p {
      font-size: 3rem;
      text-align: center;
      font-weight: bold;
      color: black;
      margin-top: 5rem;
      margin-bottom: 2rem;
    }
  }
}

.formOptions {
  padding: 2rem 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  &__optionTag {
    display: flex;
    padding: 1rem 5rem 1rem 1rem;
    border: 1.3px solid gray;
    border-radius: 0.5rem;
    flex: 1;
    min-width: calc(25% - 0.5rem); /* Adjust min-width and margin as needed */
    margin: 0.25rem; /* Adjust margin as needed */

    &:hover:not(&--optionTagSelected) {
      background-color: rgb(243, 245, 245);
      .optionIcon {
        background-color: white;
      }
    }

    &--optionTagSelected {
      background-color: #029863;
      .formOptions__optionTag__optionText {
        color: white;
      }
    }

    &__optionIcon img{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3rem;
      width: 3rem;
      background-color: rgb(243, 245, 240);
      margin-right: 0.5rem;
      border-radius: 50%;
      padding: 0.6rem;
      color: gray;
    }

    &__optionText {
      color: gray;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: large;
    }
  }
}
