.easy-integration-drive:hover {
    border-bottom: 5px solid #6a49f2;
}

@keyframes moveUpDown {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10%);
    }
  }
@keyframes moveDownUp {
    0%, 100% {
      transform: translateY(-10%);
    }
    50% {
      transform: translateY(0);
    }
  }


@media (max-width: 998px) {

    /* Hero section */
    .hero {
        flex-direction: column;
        height: 120vh !important;
        width: 100%;

        padding-top: 10px;
    }

    .hero-left {
        width: 100% !important;
        margin-bottom: 10px !important;
        height: 30% !important;

        text-align: center !important;
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .hero-left h1 {
        font-size: 34px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    .hero-left P {
        font-size: 15px !important;
        width: 100% !important;
        height: auto !important;
    }


    .hero-right {
        width: 100% !important;
        height: 70% !important;
        display: flex;
        flex-direction: column !important;
        align-items: center !important;
    }

    .hero-right img {
        height: 75% !important;
        width: 85% !important;
        margin: auto !important;
    }

    /* feature section */

    .feature {
        flex-direction: column !important;
        align-items: center !important;
        height: 130vh !important;
        text-align: center !important;
        width: 100%;
        padding: 10px;

    }

    .feature h2 {
        font-size: 34px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    .feature p {
        font-size: 15px !important;
        width: 100% !important;
        height: auto !important;
        line-height: 20px !important;
    }

    /* AI section */

    .ai-section {
        height: 140vh !important;
        text-align: center !important;
        width: 100%;

    }

    .ai-section h2 {
        font-size: 34px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    .ai-section h3 {
        font-size: 20px !important;
        font-weight: 500 !important;
        width: 100% !important;
        height: auto !important;
    }

    .ai-section p {
        font-size: 15px !important;
        width: 100% !important;
        height: auto !important;
    }

    /* Security section */

    .security {
        height: 85vh !important;
        text-align: center !important;
        width: 100%;

    }

    .security h2 {
        font-size: 34px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    .security p {
        font-size: 15px !important;
        width: 100% !important;
        height: auto !important;
    }

    /* Advanced Features */

    .advanced {
        flex-direction: column !important;
        align-items: center !important;
        height: 200vh !important;
        text-align: center !important;
        width: 100%;

    }

    .advanced h2 {
        font-size: 34px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    .advanced p {
        font-size: 15px !important;
        width: 100% !important;
        height: auto !important;
    }

    .advanced-row {
        width: 100% !important;
        height: 50% !important;
        display: flex;
        flex-direction: column;
        padding: 0 10px !important;
    }

    .advanced-card {
        width: 100% !important;
        height: 40% !important;
        border-radius: 15px;
    }

    .advanced-card h2 {
        font-size: 20px !important;
    }

    .advanced-card img {
        width: 70%;
        height: auto;
    }

    /* Easy integration */

    .easy-integration {
        height: 70vh !important;
        text-align: center !important;
        width: 100%;

    }

    .easy-integration h2 {
        font-size: 34px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    .easy-integration p {
        font-size: 20px !important;
        width: 100% !important;
        height: auto !important;
        margin-bottom: 10px !important;
    }

    .easy-integration-drive {
        width: 70% !important;
        height: auto !important;
        margin-top: 20px !important;
    }

    .easy-integration-drive:hover {
        border-bottom: 5px solid #6a49f2;
    }

    /* Interface */

    .interface {
        height: 100vh !important;
        text-align: center !important;
        width: 100%;

        margin-top: 10px !important;
    }

    .interface h2 {
        font-size: 34px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    .interface p {
        font-size: 20px !important;
        width: 100% !important;
        height: auto !important;
        margin-bottom: 20px !important;
    }

    .interface img {
        width: 85% !important;
        height: 75% !important;
    }

    /* Need support */

    .need-support {
        height: 25vh !important;
        text-align: center !important;
        width: 100%;
        margin-top: 30px !important;
        display: flex;
        gap: 15px !important;
        padding: 0 15px !important;
        margin-bottom: 0 !important;
    }

    .need-support h2 {
        font-size: 34px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
        flex: 1.3;
    }

    .need-support span {
        flex: 0.7;
        font-size: 15px !important;
        width: 100% !important;
        height: auto !important;

    }
}



@media (max-width:668px) {

    .hero {
        flex-direction: column;
        height: auto !important;
        width: 100%;
        padding-top: 10px;
    }

    .hero-left {
        width: 100% !important;
        margin-bottom: 10px !important;
        height: auto !important;

        text-align: center !important;
        display: flex;
        flex-direction: column;
        align-items: center;

    }



    /* feature section */

    .header {
        padding: 10px 30px !important;
    }

    .feature {
        flex-direction: column !important;
        align-items: center !important;
        height: 150vh !important;
        text-align: center !important;
        width: 100%;

        padding: 10px;

    }

    .feature-card-wrapper {

        display: flex;
        flex-direction: column !important;
        align-items: center !important;
        width: 100%;
        height: 100%;
    }

    .feature-card {
        width: 80% !important;
        height: auto !important;
    }

    .feature h2 {
        font-size: 30px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    .feature p {
        font-size: 12px !important;
        width: 100% !important;
        height: auto !important;
        line-height: 20px !important;
    }


    /* Ai section */

    .ai-section {
        height: 150vh !important;
        text-align: center !important;
        width: 100%;
        margin-bottom: 20px !important;
        display: flex !important;
        flex-direction: column !important;

    }

    .ai-left {
        width: 100% !important;
        height: 50% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ai-right {
        width: 100% !important;
        height: 50% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ai-right img {
        width: 100% !important;
        height: 31% !important;
    }

    .ai-section h2 {
        font-size: 30px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    .ai-section h3 {
        font-size: 15px !important;
        font-weight: 500 !important;
        width: 100% !important;
        height: auto !important;
    }

    .ai-section p {
        font-size: 12px !important;
        width: 100% !important;
        height: auto !important;
    }

    /* Security */

    .security {
        height: auto !important;
        text-align: center !important;
        width: 100%;

    }

    .security h2 {
        font-size: 30px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    .security p {
        font-size: 12px !important;
        width: 100% !important;
        height: auto !important;
    }


    /* Advanced section */

    .advanced {
        flex-direction: column !important;
        align-items: center !important;
        height: 200vh !important;
        text-align: center !important;
        width: 100%;

    }

    .advanced h2 {
        font-size: 30px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    .advanced p {
        font-size: 13px !important;
        width: 100% !important;
        height: auto !important;
    }

    .advanced-row {
        width: 100% !important;
        height: 50% !important;
        display: flex;
        flex-direction: column;
        padding: 0 10px !important;
    }

    .advanced-card {
        width: 100% !important;
        height: 40% !important;
        border-radius: 15px;
    }

    .advanced-card h2 {
        font-size: 15px !important;
    }

    .advanced-card img {
        width: 70%;
        height: auto;
    }

    /* integration */
    /* Easy integration */

    .easy-integration {
        height: 70vh !important;
        text-align: center !important;
        width: 100%;

    }

    .easy-integration h2 {
        font-size: 30px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    .easy-integration p {
        font-size: 15px !important;
        width: 100% !important;
        height: auto !important;
        margin-bottom: 10px !important;
    }

    .easy-integration-drive {
        width: 70% !important;
        height: auto !important;
        margin-top: 20px !important;
    }

    .easy-integration-drive:hover {
        border-bottom: 5px solid #6a49f2;
    }

    /* Need support */

    .need-support {
        height: 25vh !important;
        text-align: center !important;
        width: 100%;
        margin-top: 30px !important;
        display: flex;
        gap: 15px !important;
        padding: 0 15px !important;
        margin-bottom: 0 !important;
    }

    .need-support h2 {
        font-size: 25px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
        flex: 1.3;
    }

    .need-support span {
        flex: 0.7;
        font-size: 15px !important;
        width: 100% !important;
        height: auto !important;

    }

    /* Footer section */

    footer h2 {
        font-size: 35px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    footer p {
        font-size: 15px !important;
        width: 100% !important;
        height: auto !important;
    }

    footer img {
        width: 50%;
        height: auto;
    }
}



@media (max-width: 480px) {

    .hero {
        flex-direction: column;
        height: auto !important;
        width: 100%;
        padding-top: 10px;
    }

    .hero-left {
        width: 100% !important;
        margin-bottom: 10px !important;
        height: auto !important;

        text-align: center !important;
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .hero-left h1 {
        font-size: 34px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    .hero-left P {
        font-size: 15px !important;
        width: 100% !important;
        height: auto !important;
        margin-bottom: 10px !important;
    }


    .hero-right {
        width: 100% !important;
        height: auto !important;
        display: flex;
        flex-direction: column !important;
        align-items: center !important;
    }

    .hero-right img {
        height: 65% !important;
        width: 85% !important;
        margin: auto !important;
    }

    /* feature section */

    .feature {
        flex-direction: column !important;
        align-items: center !important;
        height: 1100px !important;
        text-align: center !important;
        width: 100%;
        padding: 15px !important;

    }

    .feature h2 {
        font-size: 34px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    .feature p {
        font-size: 15px !important;
        width: 100% !important;
        height: auto !important;
        line-height: 20px !important;
    }

    /* AI section */

    .ai-section {
        height: 1200px !important;
        text-align: center !important;
        width: 100%;

    }

    .ai-left {
        width: 100% !important;
        height: 50% !important;
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .ai-left-section {
        width: 100% !important;
    }

    .ai-right {
        width: 100% !important;
        height: 50% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px !important;
        box-sizing: border-box;
    }

    .ai-section h2 {
        font-size: 30px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    .ai-section h3 {
        font-size: 15px !important;
        font-weight: 500 !important;
        width: 100% !important;
        height: auto !important;
    }

    .ai-section p {
        font-size: 15px !important;
        width: 100% !important;
        height: auto !important;

    }

    .ai-right img {
        margin-top: 10px !important;
        width: 100% !important;
        height: 30% !important;
    }

    /* Security section */

    .security {
        height: auto !important;
        text-align: center !important;
        width: 100%;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;

    }

    .security-left {
        width: 100% !important;
        height: auto !important;
    }

    .security-right {
        width: 100% !important;
        height: auto !important;
    }

    .security h2 {
        font-size: 34px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    .security p {
        font-size: 15px !important;
        width: 100% !important;
        height: auto !important;
    }

    /* Advanced Features */

    .advanced {
        flex-direction: column !important;
        align-items: center !important;
        height: 1500px !important;
        text-align: center !important;
        width: 100%;

    }

    .advanced h2 {
        font-size: 34px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    .advanced p {
        font-size: 15px !important;
        width: 100% !important;
        height: auto !important;
    }

    .advanced-row {
        width: 100% !important;
        height: 50% !important;
        display: flex;
        flex-direction: column;
        padding: 0 10px !important;
    }

    .advanced-card {
        width: 100% !important;
        height: 40% !important;
        border-radius: 15px;

    }

    .advanced-card h2 {
        font-size: 20px !important;
        margin-bottom: 5px !important;
    }

    .advanced-card-para {
        line-height: 27px !important;
    }

    .advanced-card img {
        width: 100%;
        height: auto;
    }

    /* Easy integration */

    .easy-integration {
        height: 400px !important;
        text-align: center !important;
        width: 100%;

    }

    .easy-integration h2 {
        font-size: 30px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    .easy-integration p {
        font-size: 15px !important;
        width: 100% !important;
        height: auto !important;
        margin-bottom: 10px !important;
    }

    .easy-integration-drive {
        width: 70% !important;
        height: auto !important;
        margin-top: 20px !important;
    }

    .easy-integration-drive:hover {
        border-bottom: 5px solid #6a49f2;
    }

    /* Interface */

    .interface {
        height: 500px !important;
        text-align: center !important;
        width: 100%;

        margin-top: 10px !important;
    }

    .interface h2 {
        font-size: 30px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    .interface p {
        font-size: 15px !important;
        width: 100% !important;
        height: auto !important;
        margin-bottom: 20px !important;
    }

    .interface img {
        width: 95% !important;
        height: 85% !important;
    }

    /* Need support */

    .need-support {
        height: 150px !important;
        text-align: center !important;
        width: 100%;
        margin-top: 10px !important;
        display: flex;
        gap: 15px !important;
        padding: 0 15px !important;
        margin-bottom: 0 !important;
    }

    .need-support h2 {
        font-size: 23px !important;
        font-weight: 600 !important;
        width: 40% !important;
        height: auto !important;

    }

    .need-support span {
        font-size: 15px !important;
        width: 60% !important;
        height: auto !important;

    }

    .need-support span a {
        font-size: 14px !important;
    }

    /* Footer section */

    .footer-section {
        display: flex !important;
        justify-content: space-around !important;
    }

    footer h2 {
        font-size: 30px !important;
        font-weight: 600 !important;
        width: 100% !important;
        height: auto !important;
    }

    footer p {
        font-size: 12px !important;
        width: 100% !important;
        height: auto !important;
    }

    footer img {
        width: 50%;
        height: auto;
    }

    footer a {
        font-size: 15px !important;
    }
}