#right-login-div {
  width: 60%;
  margin: 20% auto;
}

@media (max-width: 700px) {
  #auth-container {
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }

  #auth-left {
    width: 100%;
    height: 30%;
    font-size: 1rem;
  }

  #right-para {
    display: none;
    margin: auto;
  }
  #right-logo{
    display: block;
  }
  #auth-right {
    width: auto;
    height: 50%;
  }

 
  #right-login-div {
    margin: "0 auto";
  }
}

@media screen and (min-width: 701px){
  #right-logo{
    display: none;

  }
}

@media (max-width: 698px) {
  #auth-footer {
    display: none;
  }

  #auth-left {
    display: none;
  }
}