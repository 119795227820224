/* ThankYouPage.css */

.thank-you-container {
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 10%;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.thank-you-heading {
    color: #029863;
    font-size: 5em;
}

.thank-you-message {
    font-size: 2em;
    color: #515444;
    line-height: 1.5;
}

.thank-you-btn {
    margin-top: 1em;
    width: 3em;
    background: #029863;
    padding: 1em 1.2em;
    font-weight: 600;
    border: none;
    color: #fff;
    border-radius: 2em;
}